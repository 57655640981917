import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Badge, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../context/authContext';

const PageList = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { cmsUser } = useAuth();

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const token = localStorage.getItem('cms_token');
      const response = await axios.get('/api/cms/pages', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPages(Array.isArray(response.data) ? response.data : []);
      console.log('Fetched pages:', response.data);
    } catch (err) {
      setError('Failed to fetch pages');
      console.error('Error fetching pages:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (pageId) => {
    if (window.confirm('Are you sure you want to delete this page?')) {
      try {
        const token = localStorage.getItem('cms_token');
        await axios.delete(`/api/cms/pages/${pageId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPages(pages.filter(page => page.id !== pageId));
      } catch (err) {
        setError('Failed to delete page');
        console.error('Error deleting page:', err);
      }
    }
  };

  if (loading) return <div>Loading pages...</div>;

  return (
    <Container className="mt-4 pt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Pages</h2>
        <Link to="/cms/pages/new" className="btn btn-primary">
          Create New Page
        </Link>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(pages) && pages.length > 0 ? pages.map(page => (
            <tr key={page.id}>
              <td>{page.title}</td>
              <td>
                <Badge bg={page.status === 'PUBLISHED' ? 'success' : 'warning'}>
                  {page.status}
                </Badge>
              </td>
              <td>{new Date(page.createdAt).toLocaleDateString()}</td>
              <td>
                <Link 
                  to={`/cms/pages/edit/${page.id}`} 
                  className="btn btn-sm btn-primary me-2"
                >
                  Edit
                </Link>
                <Button 
                  variant="danger" 
                  size="sm"
                  onClick={() => handleDelete(page.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="4" className="text-center">
                No pages found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default PageList; 