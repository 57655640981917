import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Context as AppContext } from './appContext';

const AuthContext = createContext(null);

// Safe localStorage access
const getStorageItem = (key) => {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
    return null;
  } catch (error) {
    console.warn('localStorage access denied:', error);
    return null;
  }
};

const setStorageItem = (key, value) => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (error) {
    console.warn('localStorage access denied:', error);
  }
};

const removeStorageItem = (key) => {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.warn('localStorage access denied:', error);
  }
};

export const AuthProvider = ({ children }) => {
  const [cmsUser, setCmsUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state: appState } = useContext(AppContext);

  useEffect(() => {
    checkCMSAuth();
  }, []);

  const checkCMSAuth = async () => {
    const token = getStorageItem('cms_token');
    if (token) {
      try {
        const response = await axios.get('/api/auth/me', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setCmsUser(response.data);
      } catch (error) {
        console.error('CMS Auth check failed:', error);
        removeStorageItem('cms_token');
      }
    }
    setLoading(false);
  };

  const loginCMS = async (email, password) => {
    const response = await axios.post('/api/auth/login', { email, password });
    const { token, user } = response.data;
    setStorageItem('cms_token', token);
    setCmsUser(user);
    return user;
  };

  const logoutCMS = () => {
    removeStorageItem('cms_token');
    setCmsUser(null);
  };

  const value = {
    cmsUser,
    hubspotUser: appState.user,
    loading,
    loginCMS,
    logoutCMS,
    checkCMSAuth,
    isAuthenticated: !!(appState.authToken || getStorageItem('cms_token'))
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 