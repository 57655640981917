import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { Context as AppContext } from "../context/appContext";
import axios from "axios";
import { Container, Row, Col, Form, Button, Table, Card } from "react-bootstrap";
import PaymentResult from "./PaymentResult";
import PaymentFormSDK from "./PaymentFormSDK";


const MakePaymentSdk = () => {
  const { invoiceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [sandbox, setSandbox] = useState(true);

  //   const { state } = useContext(AppContext);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [company, setCompany] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  
  const [error, setError] = useState(null);

  const getInvoice = async () => {
    try {
      const response = await axios.get(`/api/invoices/${invoiceId}`);
      console.log("response", response.data);
      if (response.data.error) {
        console.log("error", response.data.error);
        setError(response.data.error.message);
        setInvoice(null);
        setCustomer(null);
        setCompany(null);
        setSandbox(true);
      } else {
        console.log("invoice data found");
        setInvoice(response.data.invoice);
        setCustomer(response.data.customer);
        setCompany(response.data.company);
        setPublicKey(response.data.publicKey);
        setSandbox(response.data.sandbox);
      }
    } catch (error) {
      console.error("Error getting invoice for payment:", error);
    }
  };

  useEffect(() => {
    console.log("invoiceId", invoiceId);
    if (invoiceId) {
      getInvoice();
    }
  }, []);

  const resetMessages = () => {
    setPaymentResponse(null);
    setPaymentStatus(null);
  };

  const handlePaymentSuccess = (response) => {
    console.log("Payment response:", response);
    // Check for success first - code 200 and status success
    if (response.code === '200' && response.status === 'success') {
      // Handle success case
      console.log("Payment success:", response);
      setPaymentResponse(response);
      setPaymentStatus(200);
      setError(null);
    } else if (response.code === '402' || response.status === 'fail') {
      // Handle the error case
      const errorMessage = response.payload?.error?.['error-message'] || 
                          response.payload?.error || 
                          'Payment failed. Please try again.';
      setError(errorMessage);
      setPaymentResponse(null);
      setPaymentStatus(null);
    } else {
      // Handle any other unexpected cases
      setError('Unexpected response from payment system. Please try again.');
      setPaymentResponse(null);
      setPaymentStatus(null);
    }
  };

  if (!invoice) {
    return (
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="alert alert-danger" role="alert">
              No invoice found
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          {error && (
            <div className="alert alert-danger mb-4" role="alert">
              {error}
              <Button 
                variant="link" 
                className="float-end p-0" 
                onClick={() => setError(null)}
              >
                ✕
              </Button>
            </div>
          )}
          
          <h2>Invoice {invoice.invoice_number}</h2>
          <div className="mb-4">
            <p>
              Bill to: {customer.first_name} {customer.last_name}
              <br /> {company.name} <br />
              {company.address}, <br />
              {company.city}, {company.state} {company.zip}
            </p>
          </div>
          <Table bordered>
            <tbody>
              <tr>
                <td>Invoice number</td>
                <td>{invoice.invoice_number}</td>
              </tr>
              <tr>
                <td>Invoice Amount</td>
                <td>${invoice.amount_due}</td>
              </tr>
              <tr>
                <td>Payment Due Date</td>
                <td>{new Date(invoice.due_date).toLocaleDateString()}</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-right">
          </div>
          {isLoading ? (
            <Card className="p-4 mt-3">
              <Card.Body>Loading...</Card.Body>
            </Card>
          ) : (
            paymentResponse ? (
              <PaymentResult status={paymentStatus} response={paymentResponse} resetMessages={resetMessages} />
            ) : (
              <>
                <PaymentFormSDK publicKey={publicKey} onPaymentSuccess={handlePaymentSuccess} invoiceId={invoiceId} />
              </>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MakePaymentSdk;