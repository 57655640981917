import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LegalLinks from "../components/legalLinks";
import "../styles/Legal.css";

function CookiePolicyPage() {
  return (
    <div className="legal-page">
      <div className="legal-header">
        <Container className="text-center">
          <Link to="/">
            <img
              src="logo.png"
              alt="Payspot Logo"
              className="legal-logo mb-4"
            />
          </Link>
          <h1 className="legal-title">Cookie Policy</h1>
          <p className="legal-subtitle">Last Updated: December 08, 2024</p>
        </Container>
      </div>

      <div className="legal-content">
        <div className="legal-container">
          <section className="terms-content">
            <p>
              Our website uses cookies to enhance your browsing experience. By
              continuing to use our site, you agree to our use of cookies.
            </p>
          </section>
        </div>

        <footer className="footer">
          <Container>
            <Row className="g-4">
              <Col md={4}>
                <h5>Company</h5>
                <ul className="footer-links">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <h5>Resources</h5>
                <ul className="footer-links">
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <h5>Contact</h5>
                <p>Need assistance? Our support team is here to help.</p>
                <Button variant="outline-primary">Contact Support</Button>
              </Col>
            </Row>
            <div className="footer-bottom">
              <LegalLinks />
            </div>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default CookiePolicyPage;
