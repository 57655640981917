import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PageContent = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPage();
  }, [slug]);

  const fetchPage = async () => {
    try {
      const response = await axios.get(`/api/public/pages/${slug}`);
      setPage(response.data);
    } catch (err) {
      setError('Failed to fetch page');
      console.error('Error fetching page:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading page...</div>;
  if (error) return <div className="text-danger">{error}</div>;
  if (!page) return <div>Page not found</div>;

  return (
    <Container className="py-5">
      <h1 className="mb-4">{page.title}</h1>
      <div 
        className="page-content"
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
    </Container>
  );
};

export default PageContent; 