import React from 'react';
import { Container } from 'react-bootstrap';
import './DemoPage.css';

const DemoPage = () => {
  const images = [
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/aZBfepDyn3wjKmWz_EY9_.jpg&x=825&y=1271&fill=4f46e5&color=4f46e5",
      title: "1. Ready to begin? Click 'Get Started' to proceed."
    },
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/DFXYsA47PpXOHiEARatte.jpg&x=1899&y=963&fill=4f46e5&color=4f46e5",
      title: "2. Now, proceed by clicking 'Login with HubSpot'."
    },
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/39fzI2Eg3vfE5Hl2XFzdA.jpg&x=1357&y=1475&fill=4f46e5&color=4f46e5",
      title: "3. Next, select the shown option."
    },
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/P03Wq8Z_mewWI6I0L2XVY.jpg&x=1441&y=1798&fill=4f46e5&color=4f46e5",
      title: "4. Continue by choosing your account."
    },
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/VbGC9cxpiDVS7floGrtFt.jpg&x=239&y=440&fill=4f46e5&color=4f46e5",
      title: "5. Afterward, select 'Open Invoices' to view."
    },
    {
      url: "https://d16ev9qffqt5qm.cloudfront.net/?s3_key=cm6ida0pd02vnpec9hmwfk1f6/_o4SEd-pW1sYoFCsa25-p.jpg&x=850&y=783&fill=4f46e5&color=4f46e5",
      title: "6. Finish up by clicking 'Email Receipt'."
    }
  ];

  return (
    <div className="demo-page">
      <Container>
        <div className="demo-header">
          <h1>
            <a 
              href="https://app.supademo.com/demo/cm6idhgit0du0o60przboqdgk" 
              target="_blank" 
              rel="noreferrer"
            >
              Payspot Demo
            </a>
          </h1>
          <div className="demo-meta">
            <div><b>Author:</b> Ty Hudson</div>
            <div><b>Steps:</b> 6</div>
            <div>
              <b>Source:</b>{' '}
              <a 
                href="https://app.supademo.com/demo/cm6idhgit0du0o60przboqdgk" 
                target="_blank" 
                rel="noreferrer"
              >
                Supademo
              </a>
            </div>
          </div>
        </div>

        <hr />

        <div className="demo-steps">
          {images.map((step, index) => (
            <div key={index} id={`step-${index + 1}`} className="demo-step">
              <h3>{step.title}</h3>
              <img 
                src={step.url} 
                alt={`Step ${index + 1}`}
                style={{ border: '1px solid #C2C2C2' }}
              />
            </div>
          ))}
        </div>

        <hr />

        <div className="demo-footer">
          <a 
            href="https://app.supademo.com/demo/cm6idhgit0du0o60przboqdgk" 
            target="_blank" 
            rel="noreferrer"
          >
            <em>This workflow was created with Supademo.</em>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default DemoPage;
