import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const PageEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(!!id);
  const [page, setPage] = useState({
    title: '',
    content: '',
    status: 'DRAFT'
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      console.log('Fetching page with id:', id);
      fetchPage();
    }
  }, [id]);

  useEffect(() => {
    console.log('Current page state:', page);
  }, [page]);

  const fetchPage = async () => {
    try {
      const token = localStorage.getItem('cms_token');
      const response = await axios.get(`/api/cms/pages/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPage({
        title: response.data.title || '',
        content: response.data.content || '',
        status: response.data.status || 'DRAFT'
      });
    } catch (err) {
      setError('Failed to fetch page');
      console.error('Error fetching page:', err);
    } finally {
      setInitialLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const token = localStorage.getItem('cms_token');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      if (id) {
        await axios.put(`/api/cms/pages/${id}`, page, config);
      } else {
        await axios.post('/api/cms/pages', page, config);
      }

      navigate('/cms/pages');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to save page');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4 pt-3">
      <h2>{id ? 'Edit Page' : 'Create New Page'}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      
      {initialLoading ? (
        <div>Loading page...</div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={page.title}
              onChange={(e) => setPage({ ...page, title: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Content</Form.Label>
            <ReactQuill
              value={page.content}
              onChange={(content) => setPage({ ...page, content })}
              className="mb-3"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={page.status}
              onChange={(e) => setPage({ ...page, status: e.target.value })}
            >
              <option value="DRAFT">Draft</option>
              <option value="PUBLISHED">Published</option>
            </Form.Select>
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Page'}
          </Button>
          <Button 
            variant="secondary" 
            onClick={() => navigate('/cms/pages')}
            className="ms-2"
          >
            Cancel
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default PageEditor; 