import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LegalLinks from "../components/legalLinks";
import "../styles/Legal.css";

const PrivacyPolicy = () => {
  return (
    <div className="legal-page">
      <div className="legal-header">
        <Container className="text-center">
          <Link to="/">
            <img
              src="logo.png"
              alt="Payspot Logo"
              className="legal-logo mb-4"
            />
          </Link>
          <h1 className="legal-title">Privacy Policy</h1>
          <p className="legal-subtitle">Last Updated: December 08, 2024</p>
        </Container>
      </div>

      <div className="legal-content">
        <div className="legal-container">
          <section className="terms-content">
            <p>
              This Privacy Policy ("Policy") applies to
              PaySpot.app, and Tysoft LLC ("Company") and governs
              data collection and usage. For the purposes of this Privacy
              Policy, unless otherwise noted, all references to the Company
              include PaySpot.app. The Company's website is a
              payment processing and accounts receivable software site. By
              using the Company website, you consent to the data practices
              described in this statement.
            </p>

            <section className="mb-4">
              <h2>Privacy Compliance Certification</h2>
              <p>
                The Company has PCI Compliance and Attestation of Compliance
                certification.
              </p>
              <p>
                Tysoft LLC maintains compliance with the Payment Card Industry
                Data Security Standard (PCI DSS) version [X.X] to protect
                payment card data. Our compliance includes:
              </p>
              <ul>
                <li>Annual validation of compliance</li>
                <li>Maintaining security policies and procedures</li>
                <li>Regular security testing and monitoring</li>
                <li>Protection of cardholder data</li>
                <li>Maintenance of secure systems and networks</li>
              </ul>
            </section>

            <section className="mb-4">
              <h2>Collection of your Personal Information</h2>
              <p>
                In order to better provide you with products and services
                offered, the Company may collect personally identifiable
                information, such as your:
              </p>
              <ul>
                <li>First and last name</li>
                <li>Mailing address</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Job title</li>
                <li>Any other payment information</li>
              </ul>
            </section>

            <section className="mb-4">
              <h2>Additional Information Collection</h2>

              <div className="mb-3">
                <p>
                  If you purchase the Company's products and services, we
                  collect billing and credit card information. This information
                  is used to complete the purchase transaction.
                </p>
              </div>

              <div className="mb-3">
                <p>
                  The Company may also collect anonymous demographic
                  information, which is not unique to you, such as your:
                </p>
                <ul>
                  <li>Age</li>
                  <li>Gender</li>
                  <li>Race</li>
                  <li>Household income</li>
                </ul>
              </div>

              <div>
                <p>
                  We do not collect any personal information about you unless
                  you voluntarily provide it to us. However, you may be required
                  to provide certain personal information to us when you elect
                  to use certain products or services. These may include:
                </p>
                <ol type="a" className="ps-4">
                  <li>registering for an account;</li>
                  <li>
                    entering a sweepstakes or contest sponsored by us or one of
                    our partners;
                  </li>
                  <li>
                    signing up for special offers from selected third parties;
                  </li>
                  <li>sending us an email message;</li>
                  <li>
                    submitting your credit card or other payment information
                    when ordering and purchasing products and services.
                  </li>
                </ol>
                <p>
                  To wit, we will use your information for, but not limited to,
                  communicating with you in relation to services and/or products
                  you have requested from us. We also may gather additional
                  personal or non-personal information in the future.
                </p>
              </div>
            </section>

            <section className="mb-4">
              <h2>Use of your Personal Information</h2>

              <div className="mb-3">
                <p>
                  The Company collects and uses your personal information in the
                  following ways:
                </p>
                <ul className="mb-4">
                  <li>
                    to operate and deliver the services you have requested
                  </li>
                  <li>
                    to provide you with information, products, or services that
                    you request from us
                  </li>
                  <li>to provide you with notices about your account</li>
                  <li>
                    to carry out the Company's obligations and enforce our
                    rights arising from any contracts entered between you and
                    us, including for billing and collection
                  </li>
                  <li>
                    to notify you about changes to our{" "}
                    <a
                      href="https://www.payspot.app/"
                      className="text-decoration-none"
                    >
                      https://www.payspot.app/
                    </a>{" "}
                    or any products or services we offer or provide through it
                  </li>
                  <li>
                    in any other way we may describe when you provide the
                    information
                  </li>
                  <li>for any other purpose with your consent</li>
                </ul>

                <p>
                  The Company may also use your personally identifiable
                  information to inform you of other products or services
                  available from the Company and its affiliates.
                </p>
              </div>
            </section>

            <section className="mb-4">
              <h2>Sharing Information with Third Parties</h2>
              <p>
                The Company does not sell, rent, or lease its customer lists to
                third parties.
              </p>

              <p>
                The Company may, from time to time, contact you on behalf of
                external business partners about a particular offering that may
                be of interest to you. In those cases, your unique personally
                identifiable information (email, name, address, phone number) is
                transferred to the third party. The Company may share data with
                trusted partners to help perform statistical analysis, send you
                email or postal mail, provide customer support, or arrange for
                deliveries. All such third parties are prohibited from using
                your personal information except to provide these services to
                the Company, and they are required to maintain the
                confidentiality of your information.
              </p>

              <p>
                The Company may disclose your personal information, without
                notice, if required to do so by law or in the good faith belief
                that such action is necessary to:
              </p>
              <ol type="a" className="mb-4">
                <li>
                  conform to the edicts of the law or comply with legal process
                  served on the Company or the site;
                </li>
                <li>
                  protect and defend the rights or property of the Company;
                  and/or
                </li>
                <li>
                  act under exigent circumstances to protect the personal safety
                  of users of the Company, or the public.
                </li>
              </ol>
            </section>

            <section className="mb-4">
              <h2>
                Opt-Out of Disclosure of Personal Information to Third Parties
              </h2>
              <p>
                In connection with any personal information we may disclose to a
                third party for a business purpose, you have the right to know:
              </p>
              <ul className="mb-3">
                <li>
                  The categories of personal information that we disclosed about
                  you for a business purpose.
                </li>
              </ul>

              <p>
                You have the right under the California Consumer Privacy Act of
                2018 (CCPA) and certain other privacy and data protection laws,
                as applicable, to opt out of the disclosure of your personal
                information. If you exercise your right to opt out of the
                disclosure of your personal information, we will refrain from
                disclosing your personal information, unless you subsequently
                provide express authorization for the disclosure of your
                personal information. To opt out of the disclosure of your
                personal information, visit this web page{" "}
                <a
                  href="https://www.payspot.app"
                  className="text-decoration-none"
                >
                  www.payspot.app
                </a>
                .
              </p>
            </section>

            <section className="mb-4">
              <h2>Automatically Collected Information</h2>
              <p>
                The Company may automatically collect information about your
                computer hardware and software. This information can include
                your IP address, browser type, domain names, access times, and
                referring website addresses. This information is used for the
                operation of the service, to maintain quality of the service,
                and to provide general statistics regarding the use of the
                Company's website.
              </p>
            </section>

            <section className="mb-4">
              <h2>Use of Cookies</h2>
              <p>
                The Company's website may use "cookies" to help you personalize
                your online experience. A cookie is a text file that is placed
                on your hard disk by a web page server. Cookies cannot be used
                to run programs or deliver viruses to your computer. Cookies are
                uniquely assigned to you, and can only be read by a web server
                in the domain that issued the cookie to you.
              </p>

              <p>
                One of the primary purposes of cookies is to provide a
                convenience feature to save you time. The purpose of a cookie is
                to tell the web server that you have returned to a specific
                page. For example, if you personalize the Company's pages, or
                register with Company's site or services, a cookie helps the
                Company to recall your specific information on subsequent
                visits. This simplifies the process of recording your personal
                information, such as billing addresses, shipping addresses, and
                so on. When you return to the same website, the information you
                previously provided can be retrieved, so you can easily use the
                Company's features that you customized.
              </p>

              <p>
                You have the ability to accept or decline cookies. Most web
                browsers automatically accept cookies, but you can usually
                modify your browser settings to decline cookies if you prefer.
                If you choose to decline cookies, you may not be able to fully
                experience the interactive features of the Company's services or
                websites you visit.
              </p>
            </section>

            <section className="mb-4">
              <h2>Links</h2>
              <p>
                This website contains links to other sites. Please be aware that
                we are not responsible for the content or privacy practices of
                such other sites. We encourage our users to be aware when they
                leave our site and to read the privacy statements of any other
                site that collects personally identifiable information.
              </p>
            </section>

            <section className="mb-4">
              <h2>Security of your Personal Information</h2>
              <p>
                The Company secures your personal information from unauthorized
                access, use, or disclosure. The Company uses the following
                methods for this purpose:
              </p>
              <ul className="mb-3">
                <li>SSL Protocol</li>
              </ul>

              <p>
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use
                of encryption, such as the Secure Sockets Layer (SSL) protocol.
              </p>

              <p>
                We strive to take appropriate security measures to protect
                against unauthorized access to or alteration of your personal
                information. Unfortunately, no data transmission over the
                Internet or any wireless network can be guaranteed to be 100%
                secure. As a result, while we strive to protect your personal
                information, you acknowledge that:
              </p>
              <ol type="a" className="mb-3">
                <li>
                  there are security and privacy limitations inherent to the
                  Internet that are beyond our control; and
                </li>
                <li>
                  the security, integrity, and privacy of any and all
                  information and data exchanged between you and us through this
                  site cannot be guaranteed.
                </li>
              </ol>
            </section>

            <section className="mb-4">
              <h2>Right to Deletion</h2>
              <p>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
              <ul className="mb-4">
                <li>Delete your personal information from our records; and</li>
                <li>
                  Direct any service providers to delete your personal
                  information from their records.
                </li>
              </ul>

              <p>
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </p>
              <ul className="mb-4">
                <li>
                  Complete the transaction for which the personal information
                  was collected, fulfill the terms of a written warranty or
                  product recall conducted in accordance with federal law, and
                  provide a good or service requested by you, or reasonably
                  anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform a contract between
                  you and us;
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity; or prosecute those
                  responsible for that activity;
                </li>
                <li>
                  Debug to identify and repair errors that impair existing
                  intended functionality;
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her right of free speech, or exercise another
                  right provided for by law;
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act;
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when our
                  deletion of the information is likely to render impossible or
                  seriously impair the achievement of such research, provided we
                  have obtained your informed consent;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us;
                </li>
                <li>Comply with an existing legal obligation; or</li>
                <li>
                  Otherwise use your personal information, internally, in a
                  lawful manner that is compatible with the context in which you
                  provided the information.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h2>Children Under Thirteen</h2>
              <p>
                The Company does not knowingly collect personally identifiable
                information from children under the age of 13. If you are under
                the age of 13, you must ask your parent or guardian for
                permission to use this website.
              </p>
            </section>

            <section className="mb-4">
              <h2>
                Disconnecting your Company's Account from Third Party Websites
              </h2>
              <p>
                You will be able to connect your Company's account to
                third-party accounts. BY CONNECTING YOUR COMPANY'S ACCOUNT TO
                YOUR THIRD-PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE
                CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO
                OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE
                THIRD-PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU,
                INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN
                THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your
                account from a third-party account at any time.
              </p>
            </section>

            <section className="mb-4">
              <h2>Opt Out and Unsubscribe from Third-Party Communications</h2>
              <p>
                We respect your privacy and give you an opportunity to opt out
                of receiving announcements of certain information. Users may opt
                out of receiving any or all communications from third-party
                partners of the Company by contacting us here:
              </p>
              <ul className="mb-4">
                <li>
                  Web page:{" "}
                  <a
                    href="https://www.payspot.app"
                    className="text-decoration-none"
                  >
                    www.payspot.app
                  </a>
                </li>
                <li>
                  Email:{" "}
                  <a
                    href="mailto:support@payspot.app"
                    className="text-decoration-none"
                  >
                    support@payspot.app
                  </a>
                </li>
                <li>
                  Phone:{" "}
                  <a href="tel:765-405-0098" className="text-decoration-none">
                    765-405-0098
                  </a>
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h2>Email Communications</h2>
              <p>
                From time to time, the Company may contact you via email for the
                purpose of providing announcements, promotional offers, alerts,
                confirmations, surveys, and/or other general communication. In
                order to improve our services, we may receive a notification
                when you open an email from the Company or click on a link
                therein.
              </p>
              <p>
                If you would like to stop receiving marketing or promotional
                communications via email from the Company, you may opt out of
                such communications by clicking on the unsubscribe button on the
                bottom of the email.
              </p>
            </section>

            <section className="mb-4">
              <h2>External Data Storage Sites</h2>
              <p>
                We may store your data on servers provided by third-party
                hosting vendors with whom we have contracted.
              </p>
            </section>

            <section className="mb-4">
              <h2>Changes to This Statement</h2>
              <p>
                The Company reserves the right to change this Policy from time
                to time. For example, when there are changes in our services,
                changes in our data protection practices, or changes in the law.
                When changes to this Policy are significant, we will inform you.
                You may receive a notice by sending an email to the primary
                email address specified in your account, by placing a prominent
                notice on our Tysoft LLC, and/or by updating any privacy
                information.
              </p>
              <p>
                Your continued use of the website and/or services available
                after such modifications will constitute your:
              </p>
              <ol type="a" className="mb-3">
                <li>acknowledgment of the modified Policy; and</li>
                <li>agreement to abide and be bound by that Policy.</li>
              </ol>
            </section>

            <section className="mb-4">
              <h2>Contact Information</h2>
              <div className="card bg-light p-4">
                <h3>Tysoft LLC</h3>
                <p>
                  2211 S Walnut Street
                  <br />
                  Yorktown, Indiana 47396
                </p>
                <p>Email: support@payspot.app</p>
                <p>Phone: 765-405-0098</p>
              </div>
            </section>
          </section>
        </div>
      </div>

      <footer className="footer">
        <Container>
          <Row className="g-4">
            <Col md={4}>
              <h5>Company</h5>
              <ul className="footer-links">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Resources</h5>
              <ul className="footer-links">
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/terms">Terms of Service</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Need assistance? Our support team is here to help.</p>
              <Button variant="outline-primary">Contact Support</Button>
            </Col>
          </Row>
          <div className="footer-bottom">
            <LegalLinks />
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
