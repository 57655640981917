import React, { useEffect, useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./PaymentFormSDK.css";
import LegalLinks from "../components/legalLinks";

const PaymentFormSDK = ({ publicKey, onPaymentSuccess, invoiceId }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const [signature, setSignature] = useState(null);
  const sigPad = useRef({});

  useEffect(() => {
    loadSDK();
  }, [publicKey]);

  const loadSDK = async () => {
    if (
      !document.querySelector(
        'script[src="https://gateway.clearent.net/js-sdk/js/clearent-host.js"]'
      )
    ) {
      try {
        await loadScript(
          "https://gateway.clearent.net/js-sdk/js/clearent-host.js"
        );
        initializeClearentSDK();
      } catch (err) {
        setError("Failed to load Clearent SDK");
        console.error(err);
      }
    } else {
      initializeClearentSDK();
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializeClearentSDK = () => {
    window.ClearentSDK?.init({
      baseUrl: "https://gateway.clearent.net",
      pk: publicKey,
      allowAutoComplete: true,
      cardFormatted: true,
      enableAch: true,
    });
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
    setSignature(null);
  };

  const saveSignature = () => {
    if (sigPad.current.isEmpty()) {
      return; // Don't save empty signatures
    }
    setSignature(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  const handlePaymentSubmit = async () => {
    setIsProcessing(true);
    setError(null);

    // Validate fields first
    const firstName = document.querySelector(
      'input[placeholder="First Name"]'
    ).value;
    const lastName = document.querySelector(
      'input[placeholder="Last Name"]'
    ).value;
    const address = document.querySelector(
      'input[placeholder="Address"]'
    ).value;
    const address2 = document.querySelector(
      'input[placeholder="Address2"]'
    ).value;
    const city = document.querySelector('input[placeholder="City"]').value;
    const state = document.querySelector('input[placeholder="State"]').value;
    const zip = document.querySelector('input[placeholder="Zip"]').value;

    // Field validation
    if (
      !firstName ||
      !lastName ||
      !address ||
      !city ||
      !state ||
      !zip ||
      !signature
    ) {
      const missingFields = [];
      if (!firstName) missingFields.push("First Name");
      if (!lastName) missingFields.push("Last Name");
      if (!address) missingFields.push("Address");
      if (!city) missingFields.push("City");
      if (!state) missingFields.push("State");
      if (!zip) missingFields.push("Zip");
      if (!signature) missingFields.push("Signature");
      setError(
        `Please fill in all required fields: ${missingFields.join(", ")}`
      );
      setIsProcessing(false);
      return;
    }

    try {
      // Step 1: Process the payment first
      const paymentResult = await processPayment();
      if (!paymentResult?.json?.payload?.["mobile-jwt"]) {
        throw new Error("Invalid payment response received");
      }

      // Step 2: Only if payment processing succeeds, prepare and send data to server
      const paymentData = {
        signature,
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        zip,
        paymentDetails: paymentResult.json.payload["mobile-jwt"],
      };

      // Step 3: Send to server
      const response = await fetch(
        `/api/invoices/takePaymentSDK/${invoiceId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          responseData.error?.message || "Failed to process payment on server."
        );
      }

      if(responseData.payload.payloadType === "error") {
        setError(responseData.payload.error['error-message']);
      } else {
        // Step 4: Only call success callback if everything succeeded
        onPaymentSuccess?.(responseData);
      }
    } catch (err) {
      console.error("Payment processing failed:", err);
      if (err.type === "validation") {
        setError(`Validation error: ${err.messages.join(", ")}`);
      } else {
        setError(err.message || "Payment processing failed. Please try again.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const processPayment = () => {
    return new Promise((resolve, reject) => {
      // Add timeout to prevent hanging
      // const timeout = setTimeout(() => {
      //   reject(new Error("Payment processing timed out"));
      // }, 30000); // 30 second timeout

      // Set up one-time handlers for this specific payment attempt
      window.ClearentValidation = (messages) => {
        // clearTimeout(timeout);
        if (messages && messages.length > 0) {
          reject({ type: "validation", messages });
        }
      };

      window.ClearentTokenSuccess = (raw, json) => {
        // clearTimeout(timeout);
        if (!json?.payload?.["mobile-jwt"]) {
          reject(new Error("Invalid payment token received"));
          return;
        }
        resolve({ raw, json });
      };

      window.ClearentTokenError = (raw, json) => {
        // clearTimeout(timeout);
        reject({
          type: "error",
          raw,
          json,
          message: json?.error?.message || "Payment token generation failed",
        });
      };

      // Verify SDK is loaded before attempting payment
      if (!window.ClearentSDK) {
        // clearTimeout(timeout);
        reject(new Error("Payment SDK not initialized"));
        return;
      }

      // Trigger the payment token request
      try {
        window.ClearentSDK.getPaymentToken();
      } catch (error) {
        // clearTimeout(timeout);
        reject(new Error("Failed to initiate payment process"));
      }
    });
  };

  return (
    <div className="paymentForm">
      <div className="container">
        <label id="billing-label">Billing Information</label>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="First Name"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="Last Name"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input
                placeholder="Address"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input
                placeholder="Address2"
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input
                placeholder="City"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="State"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="Zip"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <label id="payment-form-label" htmlFor="payment-form">
          Payment details
        </label>
        <div className="signature-section">
          {signature ? (
            <div className="saved-signature">
              <img
                src={signature}
                alt="Signature"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
              />
              <button
                onClick={clearSignature}
                className="btn btn-secondary"
                style={{ marginLeft: "10px" }}
              >
                Clear Signature
              </button>
            </div>
          ) : (
            <div className="signature-pad-container">
              <SignatureCanvas
                ref={sigPad}
                canvasProps={{
                  className: "signature-canvas",
                  style: {
                    width: "100%",
                    height: "150px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  },
                }}
              />
              <div className="signature-buttons" style={{ marginTop: "10px" }}>
                <button
                  onClick={clearSignature}
                  className="btn btn-secondary"
                  style={{ marginRight: "10px" }}
                >
                  Clear
                </button>
                <button onClick={saveSignature} className="btn btn-primary">
                  Save Signature
                </button>
              </div>
            </div>
          )}
        </div>
        <div id="payment-form" />
        <button
          id="payment-button"
          type="button"
          className="btn btn-success"
          onClick={handlePaymentSubmit}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Submit Payment"}
        </button>
      </div>
      <LegalLinks />
    </div>
  );
};

export default PaymentFormSDK;
