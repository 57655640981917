import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../context/appContext";
import LogOut from "./logOut";
import Devices from "./Devices";
import OpenInvoices from "./OpenInvoices";
import TransactionDetails from "./TransactionDetails";
import PaymentAnalytics from "./PaymentAnalytics";

const Dashboard = () => {
  const { state, getCustomers } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("batches");
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    getCustomers();
  }, []);

  if (state.apiError) {
    return (
      <>
        <LogOut />
      </>
    );
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "batches":
        return <Devices handleDeviceClick={handleDeviceClick} />;
      case "openInvoices":
        return <OpenInvoices />;
      case "transactionDetails":
        return <TransactionDetails device={selectedDevice} />;
      case "analytics":
        return <PaymentAnalytics />;
      default:
        return null;
    }
  };

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
    setActiveTab("transactionDetails");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <nav
          className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          id="sidebarMenu"
        >
          <div className="position-sticky pt-3">
            <h3 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>PaySpot </span>
            </h3>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "batches" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => setActiveTab("batches")}
                >
                  <i className="bi bi-credit-card me-2"></i>
                  Batches Dashboard
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "openInvoices" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => setActiveTab("openInvoices")}
                >
                  <i className="bi bi-calendar-check me-2"></i>
                  Open Invoices
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "analytics" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => setActiveTab("analytics")}
                >
                  <i className="bi bi-graph-up me-2"></i>
                  Payment Analytics
                </a>
              </li>
            </ul>
            <hr />
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                Account
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a className="dropdown-item" href="#" onClick={LogOut}>Log Out</a></li>
              </ul>
            </div>
          </div>
        </nav>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Dashboard</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <button
                className="btn btn-primary d-md-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
          {renderTabContent()}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
