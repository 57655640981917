import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CMSDashboard from './Dashboard';
import PostEditor from './PostEditor';
import PostList from './PostList';
import PageList from './pages/PageList';
import PageEditor from './pages/PageEditor';
import MediaLibrary from './media/MediaLibrary';
import CMSNavBar from './CMSNavBar';
import './CMSNavBar.css';

const CMSRoutes = () => {
  return (
    <>
      <CMSNavBar />
      <Routes>
        <Route path="/" element={<CMSDashboard />} />
        <Route path="/posts" element={<PostList />} />
        <Route path="/posts/new" element={<PostEditor />} />
        <Route path="/posts/edit/:id" element={<PostEditor />} />
        <Route path="/pages" element={<PageList />} />
        <Route path="/pages/new" element={<PageEditor />} />
        <Route path="/pages/edit/:id" element={<PageEditor />} />
        <Route path="/media" element={<MediaLibrary />} />
      </Routes>
    </>
  );
};

export default CMSRoutes; 