import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LegalLinks from "../components/legalLinks";
import "../styles/Legal.css";

function TermsPage() {
  return (
    <div className="legal-page">
      <div className="legal-header">
        <Container className="text-center">
          <Link to="/">
            <img
              src="logo.png"
              alt="Payspot Logo"
              className="legal-logo mb-4"
            />
          </Link>
          <h1 className="legal-title">Terms of Service</h1>
          <p className="legal-subtitle">Effective as of December 08, 2024</p>
        </Container>
      </div>

      <div className="legal-content">
        <div className="legal-container">
          <section className="terms-content">
            <h2>Agreement between User and PaySpot.app</h2>
            <p>
              Welcome to PaySpot.app. The PaySpot.app website (the "Site") is
              comprised of various web pages operated by Tysoft LLC
              ("Tysoft"). PaySpot.app is offered to you conditioned on your
              acceptance without modification of the terms, conditions, and
              notices contained herein (the "Terms"). Your use of PaySpot.app
              constitutes your agreement to all such Terms. Please read these
              terms carefully, and keep a copy of them for your reference.
            </p>

            <p>
              payspot.app is a Software as a service, and Merchant Service's
              Site.
            </p>

            <p>
              This web application's primary purpose is to connect HubSpot to
              Clearent, allowing our customers to send invoices, make payment
              plans, and subscriptions.
            </p>

            <h2>Privacy</h2>
            <p>
              Your use of payspot.app is subject to Tysoft's Privacy Policy.
              Please review our Privacy Policy, which also governs the Site
              and informs users of our data collection practices.
            </p>

            <h2>Electronic Communications</h2>
            <p>
              Visiting payspot.app or sending emails to Tysoft constitutes
              electronic communications. You consent to receive electronic
              communications and you agree that all agreements, notices,
              disclosures and other communications that we provide to you
              electronically, via email and on the Site, satisfy any legal
              requirement that such communications be in writing.
            </p>

            <h2>Your Account</h2>
            <p>
              If you use this site, you are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer, and you agree to accept responsibility
              for all activities that occur under your account or password.
              You may not assign or otherwise transfer your account to any
              other person or entity. You acknowledge that Tysoft is not
              responsible for third party access to your account that results
              from theft or misappropriation of your account. Tysoft and its
              associates reserve the right to refuse or cancel service,
              terminate accounts, or remove or edit content in our sole
              discretion.
            </p>

            <h2>Children Under Thirteen</h2>
            <p>
              Tysoft does not knowingly collect, either online or offline,
              personal information from persons under the age of thirteen. If
              you are under 18, you may use payspot.app only with permission
              of a parent or guardian.
            </p>

            <h2>Cancellation/Refund Policy</h2>
            <p>No refund for services provided.</p>

            <h2>Links to Third Party Sites/Third Party Services</h2>
            <p>
              payspot.app may contain links to other websites ("Linked
              Sites"). The Linked Sites are not under the control of Tysoft
              and Tysoft is not responsible for the contents of any Linked
              Site, including without limitation any link contained in a
              Linked Site, or any changes or updates to a Linked Site. Tysoft
              is providing these links to you only as a convenience, and the
              inclusion of any link does not imply endorsement by Tysoft of
              the site or any association with its operators.
            </p>

            <p>
              Certain services made available via payspot.app are delivered by
              third party sites and organizations. By using any product,
              service or functionality originating from the payspot.app
              domain, you hereby acknowledge and consent that Tysoft may share
              such information and data with any third party with whom Tysoft
              has a contractual relationship to provide the requested product,
              service or functionality on behalf of payspot.app users and
              customers.
            </p>

            <h2>No Unlawful or Prohibited Use/Intellectual Property</h2>
            <p>
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use payspot.app strictly in accordance
              with these terms of use. As a condition of your use of the Site,
              you warrant to Tysoft that you will not use the Site for any
              purpose that is unlawful or prohibited by these Terms. You may
              not use the Site in any manner which could damage, disable,
              overburden, or impair the Site or interfere with any other
              party's use and enjoyment of the Site. You may not obtain or
              attempt to obtain any materials or information through any means
              not intentionally made available or provided for through the
              Site.
            </p>

            <p>
              All content included as part of the Service, such as text,
              graphics, logos, images, as well as the compilation thereof, and
              any software used on the Site, is the property of Tysoft or its
              suppliers and protected by copyright and other laws that protect
              intellectual property and proprietary rights. You agree to
              observe and abide by all copyright and other proprietary
              notices, legends or other restrictions contained in any such
              content and will not make any changes thereto.
            </p>

            <p>
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part,
              found on the Site. Tysoft content is not for resale. Your use of
              the Site does not entitle you to make any unauthorized use of
              any protected content, and in particular you will not delete or
              alter any proprietary rights or attribution notices in any
              content. You will use protected content solely for your personal
              use, and will make no other use of the content without the
              express written permission of Tysoft and the copyright owner.
              You agree that you do not acquire any ownership rights in any
              protected content. We do not grant you any licenses, express or
              implied, to the intellectual property of Tysoft or our licensors
              except as expressly authorized by these Terms.
            </p>

            <h2>Third Party Accounts</h2>
            <p>
              You will be able to connect your Tysoft account to third party
              accounts. By connecting your Tysoft account to your third party
              account, you acknowledge and agree that you are consenting to
              the continuous release of information about you to others (in
              accordance with your privacy settings on those third party
              sites). If you do not want information about you to be shared in
              this manner, do not use this feature.
            </p>

            <h2>International Users</h2>
            <p>
              The Service is controlled, operated and administered by Tysoft
              from our offices within the USA. If you access the Service from
              a location outside the USA, you are responsible for compliance
              with all local laws. You agree that you will not use the Tysoft
              Content accessed through payspot.app in any country or in any
              manner prohibited by any applicable laws, restrictions or
              regulations.
            </p>

            <h2>Indemnification</h2>
            <p>
              You agree to indemnify, defend and hold harmless Tysoft, its
              officers, directors, employees, agents and third parties, for
              any losses, costs, liabilities and expenses (including
              reasonable attorney's fees) relating to or arising out of your
              use of or inability to use the Site or services, any user
              postings made by you, your violation of any terms of this
              Agreement or your violation of any rights of a third party, or
              your violation of any applicable laws, rules or regulations.
              Tysoft reserves the right, at its own cost, to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, in which event you will fully cooperate
              with Tysoft in asserting any available defenses.
            </p>

            <h2>Class Action Waiver</h2>
            <p>
              Any arbitration under these Terms and Conditions will take place
              on an individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
              ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
              REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
              ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both
              you and Tysoft agree otherwise, the arbitrator may not
              consolidate more than one person's claims, and may not otherwise
              preside over any form of a representative or class proceeding.
            </p>

            <h2>Liability Disclaimer</h2>
            <p>
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
              AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
              TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
              INFORMATION HEREIN. TYSOFT LLC AND/OR ITS SUPPLIERS MAY MAKE
              IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </p>

            <p>
              TYSOFT LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
              THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
              ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
              RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
              MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
              INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
              TYSOFT LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES
              AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE,
              PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            </p>

            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL TYSOFT LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES
              OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES
              FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
              CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
              DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
              PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
              INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
              OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE
              OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY OR OTHERWISE, EVEN IF TYSOFT LLC OR ANY OF ITS
              SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
              BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
              THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
              DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
              TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
              USING THE SITE.
            </p>

            <h2>Termination/Access Restriction</h2>
            <p>
              Tysoft reserves the right, in its sole discretion, to terminate
              your access to the Site and the related services or any portion
              thereof at any time, without notice. To the maximum extent
              permitted by law, this agreement is governed by the laws of the
              State of Indiana and you hereby consent to the exclusive
              jurisdiction and venue of courts in Indiana in all disputes
              arising out of or relating to the use of the Site. Use of the
              Site is unauthorized in any jurisdiction that does not give
              effect to all provisions of these Terms, including, without
              limitation, this section.
            </p>

            <p>
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and Tysoft as a result of
              this agreement or use of the Site. Tysoft's performance of this
              agreement is subject to existing laws and legal process, and
              nothing contained in this agreement is in derogation of Tysoft's
              right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of the Site or
              information provided to or gathered by Tysoft with respect to
              such use. If any part of this agreement is determined to be
              invalid or unenforceable pursuant to applicable law including,
              but not limited to, the warranty disclaimers and liability
              limitations set forth above, then the invalid or unenforceable
              provision will be deemed superseded by a valid, enforceable
              provision that most closely matches the intent of the original
              provision and the remainder of the agreement shall continue in
              effect.
            </p>

            <p>
              Unless otherwise specified herein, this agreement constitutes
              the entire agreement between the user and Tysoft with respect to
              the Site and it supersedes all prior or contemporaneous
              communications and proposals, whether electronic, oral or
              written, between the user and Tysoft with respect to the Site. A
              printed version of this agreement and of any notice given in
              electronic form shall be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions
              as other business documents and records originally generated and
              maintained in printed form. It is the express wish to the
              parties that this agreement and all related documents be written
              in English.
            </p>

            <h2>Changes to Terms</h2>
            <p>
              Tysoft reserves the right, in its sole discretion, to change the
              Terms under which payspot.app is offered. The most current
              version of the Terms will supersede all previous versions.
              Tysoft encourages you to periodically review the Terms to stay
              informed of our updates.
            </p>

            <h2>Contact Us</h2>
            <p>
              Tysoft welcomes your questions or comments regarding the Terms:
            </p>

            <address className="mb-4">
              Tysoft LLC
              <br />
              2211 S Walnut St
              <br />
              Yorktown, Indiana 47396
            </address>

            <p>
              <strong>Email Address:</strong>
              <br />
              <a href="mailto:support@payspot.app">support@payspot.app</a>
            </p>

            <p>
              <strong>Telephone number:</strong>
              <br />
              <a href="tel:765-405-0098">765-405-0098</a>
            </p>
          </section>
        </div>
      </div>

      <footer className="footer">
        <Container>
          <Row className="g-4">
            <Col md={4}>
              <h5>Company</h5>
              <ul className="footer-links">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Resources</h5>
              <ul className="footer-links">
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/terms">Terms of Service</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Need assistance? Our support team is here to help.</p>
              <Button variant="outline-primary">Contact Support</Button>
            </Col>
          </Row>
          <div className="footer-bottom">
            <LegalLinks />
          </div>
        </Container>
      </footer>
    </div>
  );
}

export default TermsPage;
