import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const PostEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(!!id);
  const [post, setPost] = useState({
    title: '',
    content: '',
    status: 'DRAFT',
    featuredImage: null
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (id) {
      console.log('Fetching post with id:', id);
      fetchPost();
    }
  }, [id]);

  useEffect(() => {
    console.log('Current post state:', post);
  }, [post]);

  const fetchPost = async () => {
    try {
      const token = localStorage.getItem('cms_token');
      const response = await axios.get(`/api/cms/posts/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPost({
        title: response.data.title || '',
        content: response.data.content || '',
        status: response.data.status || 'DRAFT',
        featuredImage: response.data.featuredImage || null
      });
      if (response.data.featuredImage) {
        setImagePreview(response.data.featuredImage);
      }
    } catch (err) {
      setError('Failed to fetch post');
      console.error('Error fetching post:', err);
    } finally {
      setInitialLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const formData = new FormData();
    formData.append('title', post.title);
    formData.append('content', post.content);
    formData.append('status', post.status);
    if (post.featuredImage instanceof File) {
      formData.append('featuredImage', post.featuredImage);
    }

    try {
      const token = localStorage.getItem('cms_token');
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      if (id) {
        await axios.put(`/api/cms/posts/${id}`, formData, config);
      } else {
        await axios.post('/api/cms/posts', formData, config);
      }

      navigate('/cms/posts');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to save post');
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPost({ ...post, featuredImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <Container className="mt-4 pt-3">
      <h2>{id ? 'Edit Post' : 'Create New Post'}</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      
      {initialLoading ? (
        <div>Loading post...</div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={post.title}
              onChange={(e) => setPost({ ...post, title: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Content</Form.Label>
            <ReactQuill
              value={post.content}
              onChange={(content) => setPost({ ...post, content })}
              className="mb-3"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={post.status}
              onChange={(e) => setPost({ ...post, status: e.target.value })}
            >
              <option value="DRAFT">Draft</option>
              <option value="PUBLISHED">Published</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Featured Image</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
            {imagePreview && (
              <img 
                src={imagePreview} 
                alt="Preview" 
                className="mt-2"
                style={{ maxWidth: '200px' }} 
              />
            )}
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Post'}
          </Button>
          <Button 
            variant="secondary" 
            onClick={() => navigate('/cms/posts')}
            className="ms-2"
          >
            Cancel
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default PostEditor; 