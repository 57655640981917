import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Alert, Form, ProgressBar } from 'react-bootstrap';
import { useAuth } from '../../../context/authContext';
import axios from 'axios';

const MediaLibrary = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { cmsUser } = useAuth();

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const token = localStorage.getItem('cms_token');
      const response = await axios.get('/api/cms/media', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFiles(response.data);
    } catch (err) {
      setError('Failed to fetch media files');
      console.error('Error fetching files:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('isPublic', 'true');

    try {
      const token = localStorage.getItem('cms_token');
      await axios.post('/api/cms/media/upload', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        }
      });

      setUploadProgress(0);
      fetchFiles();
    } catch (err) {
      setError('Failed to upload file');
      console.error('Error uploading file:', err);
    }
  };

  const handleDelete = async (fileId) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;

    try {
      const token = localStorage.getItem('cms_token');
      await axios.delete(`/api/cms/media/${fileId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFiles(files.filter(file => file.id !== fileId));
    } catch (err) {
      setError('Failed to delete file');
      console.error('Error deleting file:', err);
    }
  };

  if (loading) return <div>Loading media library...</div>;

  return (
    <Container className="mt-4 pt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Media Library</h2>
        <Form.Group>
          <Form.Label className="btn btn-primary mb-0">
            Upload New File
            <Form.Control
              type="file"
              onChange={handleFileUpload}
              hidden
            />
          </Form.Label>
        </Form.Group>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}
      
      {uploadProgress > 0 && (
        <ProgressBar 
          now={uploadProgress} 
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      <Row>
        {files.map(file => (
          <Col key={file.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              {file.mimeType.startsWith('image/') ? (
                <Card.Img
                  variant="top"
                  src={file.localUrl}
                  style={{ height: '200px', objectFit: 'cover' }}
                />
              ) : (
                <div className="text-center p-4">
                  <i className="fas fa-file fa-3x" />
                </div>
              )}
              <Card.Body>
                <Card.Title className="text-truncate">{file.originalName}</Card.Title>
                <Card.Text>
                  <small className="text-muted">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </small>
                </Card.Text>
                <div className="d-flex justify-content-between">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => window.open(file.localUrl, '_blank')}
                  >
                    View
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleDelete(file.id)}
                  >
                    Delete
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MediaLibrary; 