import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';

const PrivateRoute = ({ children, requireCMS = true }) => {
  const { cmsUser, hubspotUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  // If CMS access is required, check for CMS user
  if (requireCMS && !cmsUser) {
    return <Navigate to="/cms/login" />;
  }

  // If general access is required, check for either authentication
  if (!requireCMS && !cmsUser && !hubspotUser) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute; 