import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Badge, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/authContext';

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { cmsUser } = useAuth();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem('cms_token');
      const response = await axios.get('/api/cms/posts', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPosts(response.data);
    } catch (err) {
      setError('Failed to fetch posts');
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const token = localStorage.getItem('cms_token');
        await axios.delete(`/api/cms/posts/${postId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPosts(posts.filter(post => post.id !== postId));
      } catch (err) {
        setError('Failed to delete post');
        console.error('Error deleting post:', err);
      }
    }
  };

  if (loading) return <div>Loading posts...</div>;

  return (
    <Container className="mt-4 pt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Posts</h2>
        <Link to="/cms/posts/new" className="btn btn-primary">
          Create New Post
        </Link>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {posts.map(post => (
            <tr key={post.id}>
              <td>{post.title}</td>
              <td>
                <Badge bg={post.status === 'PUBLISHED' ? 'success' : 'warning'}>
                  {post.status}
                </Badge>
              </td>
              <td>{new Date(post.createdAt).toLocaleDateString()}</td>
              <td>
                <Link 
                  to={`/cms/posts/edit/${post.id}`} 
                  className="btn btn-sm btn-primary me-2"
                >
                  Edit
                </Link>
                <Button 
                  variant="danger" 
                  size="sm"
                  onClick={() => handleDelete(post.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default PostList; 