import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import logo from '../../logo.png'; // Path updated to match logo location in the public folder

const CMSNavBar = () => {
  const location = useLocation();
  const { cmsUser, logoutCMS } = useAuth();

  const isActive = (path) => {
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  return (
    <Navbar bg="white" expand="lg" className="shadow-sm">
      <Container fluid>
        <Navbar.Brand as={Link} to="/cms" className="d-flex align-items-center">
          <img
            src={logo}
            height="30"
            className="d-inline-block align-top me-2"
            alt="PaySpot Logo"
          />
          <span className="text-primary">PaySpot CMS</span>
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="cms-navbar-nav" />
        <Navbar.Collapse id="cms-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link 
              as={Link} 
              to="/cms" 
              className={isActive('/cms')}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/cms/posts" 
              className={isActive('/cms/posts')}
            >
              Posts
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/cms/pages" 
              className={isActive('/cms/pages')}
            >
              Pages
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/cms/media" 
              className={isActive('/cms/media')}
            >
              Media
            </Nav.Link>
          </Nav>
          
          <Nav>
            {cmsUser && (
              <>
                <Nav.Item className="d-flex align-items-center me-3">
                  <span className="text-muted">
                    Welcome, {cmsUser.name}
                  </span>
                </Nav.Item>
                <Nav.Link 
                  onClick={logoutCMS}
                  className="text-danger"
                >
                  Logout
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CMSNavBar; 