import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LegalLinks from '../legalLinks';
import './Blog.css';

const PostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  const fetchPost = async () => {
    try {
      const response = await axios.get(`/api/public/posts/${slug}`);
      setPost(response.data);
    } catch (err) {
      setError('Failed to fetch post');
      console.error('Error fetching post:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading post...</div>;
  if (error) return <div className="text-danger">{error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div className="blog-page">
      <div className="post-header">
        <Container className="text-center">
          <Link to="/">
            <img 
              src="/logo.png"
              alt="PaySpot Logo"
              className="blog-logo mb-4"
            />
          </Link>
          <h1 className="blog-title">{post.title}</h1>
          <p className="blog-subtitle">
            {new Date(post.createdAt).toLocaleDateString()}
          </p>
        </Container>
      </div>
      <div className="post-content">
        <div className="post-container">
          {post.featuredImage && (
            <img 
              src={post.featuredImage} 
              alt={post.title}
              style={{ 
                width: '100%', 
                height: 'auto', 
                borderRadius: '12px',
                marginBottom: '2rem'
              }}
            />
          )}
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      </div>
      {/* Footer */}
      <footer className="footer">
        <Container>
          <Row className="g-4">
            <Col md={4}>
              <h5>Company</h5>
              <ul className="footer-links">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Resources</h5>
              <ul className="footer-links">
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/terms">Terms of Service</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Need assistance? Our support team is here to help.</p>
              <Button variant="outline-primary">Contact Support</Button>
            </Col>
          </Row>
          <div className="footer-bottom">
            <LegalLinks />
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default PostDetail; 