import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';
import { Context as AppContext } from '../context/appContext';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const PaymentAnalytics = () => {
  const { state } = useContext(AppContext);
  const [timeframe, setTimeframe] = useState('month');
  const [paymentData, setPaymentData] = useState({
    dailyPayments: [],
    paymentMethods: {},
    transactionStatus: {},
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalyticsData();
  }, [timeframe]);

  const fetchAnalyticsData = async () => {
    try {
      const response = await axios.get(`/api/analytics/payments?timeframe=${timeframe}`, {
        headers: {
          Authorization: `Bearer ${state.authToken}`
        }
      });
      setPaymentData(response.data);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setLoading(false);
    }
  };

  const lineChartData = {
    labels: paymentData.dailyPayments.map(d => d.date),
    datasets: [
      {
        label: 'Daily Payment Volume ($)',
        data: paymentData.dailyPayments.map(d => d.amount),
        borderColor: '#3AD83A',
        backgroundColor: 'rgba(58, 216, 58, 0.1)',
        fill: true,
      },
    ],
  };

  const doughnutData = {
    labels: Object.keys(paymentData.paymentMethods),
    datasets: [
      {
        data: Object.values(paymentData.paymentMethods),
        backgroundColor: [
          '#3AD83A', // VISA
          '#2FBF2F', // MASTERCARD
          '#28A628', // AMEX
          '#218C21', // DISCOVER
          '#1A731A', // JCB
          '#145C14', // DINERS
        ],
      },
    ],
  };

  const barData = {
    labels: Object.keys(paymentData.transactionStatus),
    datasets: [
      {
        label: 'Transaction Status',
        data: Object.values(paymentData.transactionStatus),
        backgroundColor: '#3AD83A',
        barThickness: 40,
      },
    ],
  };

  if (loading) {
    return <div>Loading analytics...</div>;
  }

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Payment Analytics</h2>
        <Form.Select 
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          style={{ width: 'auto' }}
        >
          <option value="week">Last 7 Days</option>
          <option value="month">Last 30 Days</option>
          <option value="year">Last 12 Months</option>
        </Form.Select>
      </div>

      <Row className="g-4">
        <Col lg={8}>
          <Card>
            <Card.Body style={{ height: '400px' }}>
              <Card.Title>Payment Volume Over Time</Card.Title>
              <Line 
                data={lineChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        callback: (value) => `$${value}`
                      }
                    }
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card>
            <Card.Body style={{ height: '400px' }}>
              <Card.Title>Payment Methods</Card.Title>
              <Doughnut 
                data={doughnutData}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      position: 'right',
                    },
                  },
                }}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={12}>
          <Card>
            <Card.Body style={{ height: '300px' }}>
              <Card.Title>Transaction Status Distribution</Card.Title>
              <Bar 
                data={barData}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      min: 0,
                      max: Math.max(...Object.values(paymentData.transactionStatus)) + 2,
                      ticks: {
                        stepSize: 1,
                        precision: 0
                      }
                    }
                  },
                  hover: {
                    mode: 'nearest',
                    intersect: true
                  },
                  animation: {
                    duration: 500
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentAnalytics; 