import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LegalLinks from '../legalLinks';
import './Blog.css';

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('/api/public/posts');
      setPosts(response.data);
    } catch (err) {
      setError('Failed to fetch posts');
      console.error('Error fetching posts:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading posts...</div>;
  if (error) return <div className="text-danger">{error}</div>;

  return (
    <div className="blog-page">
      <div className="blog-header">
        <Container className="text-center">
          <h1 className="blog-title">Latest Updates</h1>
          <p className="blog-subtitle">
            Stay informed about the latest features, updates, and best practices for payment processing
          </p>
        </Container>
      </div>
      <div className="blog-content">
        <Container>
          <div className="blog-grid">
            {posts.map(post => (
              <article key={post.id} className="blog-card">
                {post.featuredImage && (
                  <div className="blog-card-image">
                    <img src={post.featuredImage} alt={post.title} />
                  </div>
                )}
                <div className="blog-card-content">
                  <h2 className="blog-card-title">{post.title}</h2>
                  <p className="blog-card-text">
                    {post.content.replace(/<[^>]*>/g, '').substring(0, 150)}...
                  </p>
                  <Link to={`/blog/${post.slug}`} className="blog-card-link">
                    Read More <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </article>
            ))}
          </div>
        </Container>
      </div>
      {/* Footer */}
      <footer className="footer">
        <Container>
          <Row className="g-4">
            <Col md={4}>
              <h5>Company</h5>
              <ul className="footer-links">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Resources</h5>
              <ul className="footer-links">
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/terms">Terms of Service</Link></li>
                <li><Link to="/privacy">Privacy Policy</Link></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Need assistance? Our support team is here to help.</p>
              <Button variant="outline-primary">Contact Support</Button>
            </Col>
          </Row>
          <div className="footer-bottom">
            <LegalLinks />
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default PostList; 